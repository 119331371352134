window.Vue = require('vue')

// import message from './components/message.vue'
// import modal from './components/modal.vue'
// import VideoModal from './components/VideoModal.vue'
// import tabs from './components/tabs.vue'
// import tab from './components/tab.vue'
// import slider from './components/slider.vue'
// import slide from './components/slide.vue'
// import GoogleMap from './components/GoogleMap.vue'
// import GoogleMapPin from './components/GoogleMapPin.vue'
// import VueParallaxJs from 'vue-parallax-js'

Vue.component('message', require('./components/message.vue').default);
Vue.component('slider', require('./components/slider.vue').default);
Vue.component('slide', require('./components/slide.vue').default);
Vue.component('google-map', require('./components/GoogleMap.vue').default);
Vue.component('google-map-pin', require('./components/GoogleMapPin.vue').default);

// window.Vue.use(VueParallaxJs, {
//     minWidth: 1000,
// })

Vue.config.devtools = true

let app = new Vue({

    el: '#app',

    // components: {
    //     'slider': slider,
    //     'slide': slide,
    //     'google-map': GoogleMap,
    //     'pin': GoogleMapPin,
    // },

    data: {
        isOpen: false,
        isScrolling: false,
        modalOpen: false,
        modalContent: '',
        scrollPosition: 0,
        footerStuck: false,
        clientHeight: 0,
        windowHeight: 0,
        windowWidth: 0,
        menuItems: []
    },

    methods: {

        toggleMenu () {
            this.isOpen = !this.isOpen;
        },

        handleScroll () {
            this.scrollPosition = window.scrollY;
            this.isScrolling = this.scrollPosition > 40;
        },

        handleMobileSubMenu () {
            this.menuItems.forEach(menuItem => {
                let menuLink = menuItem.querySelector('.mobile-expand');
                if (menuLink != null) {
                    menuLink.addEventListener('click', function (e) {
                        e.preventDefault();
                        let menu = menuItem.querySelector('.navbar-dropdown');
                        if (menu.classList.contains('is-open')) {
                            menu.classList.remove('is-open')
                        } else {
                            menu.classList.add('is-open')
                        }
                    })
                }
            })
        }

    },

    mounted: function () {
        this.footerStuck = window.innerHeight > this.$root.$el.clientHeight;
        this.clientHeight = this.$root.$el.clientHeight;
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
        this.handleScroll();
        this.menuItems = this.$el.querySelectorAll('#MobileNavMenu .navbar-item');
        this.handleMobileSubMenu();
    },

    created: function () {
        window.addEventListener('scroll', this.handleScroll)
    },

    destroyed: function () {
        window.removeEventListener('scroll', this.handleScroll)
    }

});